import { useMediaQuery } from "@mui/material";
import React from "react";

const ServicePageExplore = () => {
  const isMobile = useMediaQuery("(max-width:547px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  return (
    <div
      className="flex relative"
      style={{
        gap: "18px",
        justifyContent: "flex-end",
        alignItems: isMobile && "flex-start",
        height: isMobile ? "730px" : isTab ? "580px" : "1150px",
        padding: isMobile && "8px",
        marginTop:isMobile&&"84px"
      }}
    >
      {isMobile ? (
        <img
          src="/Images/servicePage/exploreService/banner3.png"
          alt="banner"
          style={{
            position: "absolute",
            top: "190px",
            left: "-356px",
            maxWidth: "200%",
          }}
        />
      ) : (
        <img
          src="/Images/servicePage/exploreService/banner1.png"
          alt=""
          style={{
            position: "absolute",
            left: isTab ? "-418px" : "-600px",
            display: isMobile && "none",
          }}
        />
      )}

      <div
        className="flex relative flex-col relative"
        style={{
          alignItems: "flex-start",
          width: "400px",
          flexWrap: "wrap",
          paddingRight: isMobile && "30px",
          width:"40%",
          marginBottom:"30px"
        }}
      >
        <img
          src="/Images/servicePage/exploreService/Ellipse.png"
          alt="textc"
          style={{
            position: "absolute",
            top: "-50px",
            right: "110px",
            display: isMobile && "none",
            width:"100px",
            height:"100px"
          }}
        />
        <h3
          style={{
            fontSize: isMobile ? "25px" : "35px",
            fontWeight:"500",
            textAlign: "start",
            marginBottom: "14px"
          }}
        >
          Why
          <span style={{ color: "#407BFF" }}> Startup </span>
          Fails?
        </h3>
        <p
          style={{
            textAlign: "start",
            fontSize: isMobile ? "14px" : "25px",
            color: "#5b6569",
            fontWeight:"400",
            marginBottom: "14px",
            width:"90%"
          }}
        >
          Our core focus is on providing essential business services that
          support the foundational needs to improve the chances of startup
          success.{" "}
        </p>
        <p
          style={{
            textAlign: "start",
            fontSize: isMobile ? "14px" : "25px",
            fontWeight:"400",
            color: "#5b6569",
            marginBottom: isMobile ? "30px" : "14px",
            width:"90%"
          }}
        >
          We are addressing issues that causes startup failures.
        </p>
        <button className="bg-[#407BFF] text-white hover:bg-[#2852ac]">
          <p className="text-[25px] font-semibold">Explore Services</p>
        </button>
      </div>
    </div>
  );
};

export default ServicePageExplore;
